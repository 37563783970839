<template>
  <div class='page-container'>
    <el-card>
      <el-row>
        <el-col class='header-button'>
          <el-button @click='closePublish'>取 消</el-button>
          <el-button type='primary' @click='addLectureHandle'>立即发布</el-button>
        </el-col>
      </el-row>
      <el-row>
        <el-form ref='form' :model='submitData' label-width='100px'>
          <el-col>
            <el-col :span='8'>
              <el-form-item label='人数限制' prop='peopleAmount'>
                <el-input-number v-model='submitData.peopleAmount' :min='1' />
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='开始时间' prop='startTime'>
                <el-date-picker v-model='submitData.startTime' type='datetime' placeholder='开始时间'
                                value-format='yyyy-MM-dd HH:mm:ss' />
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='结束时间' prop='endTime'>
                <el-date-picker v-model='submitData.endTime' type='datetime' placeholder='结束时间'
                                value-format='yyyy-MM-dd HH:mm:ss' />
              </el-form-item>
            </el-col>
          </el-col>
          <el-col>
            <el-col :span='8'>
              <el-form-item label='是否启用' prop='startUpFlag'>
                <el-switch v-model='submitData.startUpFlag' :active-value='1' :inactive-value='0' active-text='开启'
                           inactive-text='关闭' />
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='报名开始时间' prop='enrollStartTime'>
                <el-date-picker v-model='submitData.enrollStartTime' type='datetime' placeholder='报名开始时间'
                                value-format='yyyy-MM-dd HH:mm:ss' />
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='报名结束时间' prop='enrollEndTime'>
                <el-date-picker v-model='submitData.enrollEndTime' type='datetime' placeholder='报名结束时间'
                                value-format='yyyy-MM-dd HH:mm:ss' />
              </el-form-item>
            </el-col>
          </el-col>
          <el-col>
            <el-col :span='8'>
              <el-form-item label='主题' prop='lectureName'>
                <el-input v-model.trim='submitData.lectureName' />
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='讲座地址' prop='address'>
                <el-input v-model.trim='submitData.address' />
              </el-form-item>
            </el-col>
            <el-col :span='8'>
              <el-form-item label='备注' prop='remarks'>
                <el-input v-model.trim='submitData.remarks' />
              </el-form-item>
            </el-col>
          </el-col>
          <!-- 编辑器 -->
          <el-card>
            <vue-tinymce
              @change='changeContent'
              :value='submitData.content'
              :imagesUploadHandler='imagesUploadHandler'
            />
          </el-card>
        </el-form>
      </el-row>
    </el-card>
  </div>
</template>

<script>
import { addLecture, getLectureById } from '@/api/lecture'
import { newsUploadImg } from '@/service/newsHttp'

const host = process.env.VUE_APP_FILE_URL

export default {
  created () {
    if (this.$route.query.id) {
      this.getLectureById(this.$route.query.id)
    }
  },
  data () {
    return {
      submitData: {
        id: '',
        lectureName: '',
        startTime: '',
        endTime: '',
        enrollStartTime: '',
        enrollEndTime: '',
        peopleAmount: '',
        startUpFlag: '',
        remarks: '',
        address: '',
        content: ''
      }
    }
  },
  methods: {
    // 发布讲座
    addLectureHandle () {
      this.$refs.form.validate((valid) => {
        if (valid) {
          addLecture(this.submitData).then((res) => {
            this.$refs.form.resetFields()
            this.$message.success('添加成功')
            // this.$emit('successHandle')
            this.closePublish()
          })
        }
      })
    },

    // 根据id获取讲座
    getLectureById (id) {
      getLectureById(id).then((res) => {
        Object.keys(this.submitData).forEach((item) => {
          this.submitData[item] = res.data[item]
        })
      })
    },

    // 重置表单
    resetFormData () {
      Object.keys(this.submitData).map(key => {
        this.submitData[key] = ''
      })
      // this.submitData = {
      //   id: '',
      //   lectureName: '',
      //   startTime: '',
      //   endTime: '',
      //   enrollStartTime: '',
      //   enrollEndTime: '',
      //   peopleAmount: '',
      //   startUpFlag: '',
      //   remarks: '',
      //   content: '',
      //   address: ''
      // }
    },

    // 编辑内容
    changeContent (richText) {
      this.submitData.content = richText
    },

    // 取消发布
    closePublish () {
      this.$router.push('/lecture/lecturePublish')
    },

    // 图片上传处理
    imagesUploadHandler (blobInfo, success, failure) {
      newsUploadImg(blobInfo.blob()).then((res) => {
        success(host + res.data.data)
      })
    }
  }
}
</script>
