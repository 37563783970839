import request from '@/service/request'

// 获取消息通知列表
const getLectureList = (params) => {
  return request.post('/app/lecture/page', params)
}

// 添加消息通知
const addLecture = (params) => {
  return request.post('/app/lecture/save', params)
}

// 删除消息通知
const deleteLecture = (params) => {
  return request.post('/app/lecture/del/' + params)
}

// 根据id获取消息通知信息
const getLectureById = (params) => {
  return request.post('/app/lecture/getById/' + params)
}

export {
  getLectureList,
  addLecture,
  deleteLecture,
  getLectureById
}
