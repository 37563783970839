import axios from 'axios'
import { Loading } from 'element-ui'

let loading
const host = process.env.VUE_APP_API_URL

function startLoading () {
  loading = Loading.service({
    lock: true,
    text: '正在加载',
    background: 'rgba(0, 0, 0, 0)'
  })
}

function endLoading () {
  loading.close()
}

/* 请求导入图片，用于富文本中 */
const newsUploadImg = (formData) => {
  const data = new FormData()
  data.append('file', formData)
  startLoading()
  return new Promise((resolve, reject) => {
    axios({
      url: host + '/auth/upload/image',
      method: 'post',
      data: data,
      headers: {
        'Content-Type': 'multipart/form-data',
        Authorization: window.sessionStorage.getItem('token')
      }
    }).then((res) => {
      endLoading()
      resolve(res)
    }).catch(err => {
      endLoading()
      reject(err)
    })
  })
}

export {
  newsUploadImg
}
